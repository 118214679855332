import pdfcpu from "url:./pdfcpu.wasm";
import debug from "debug";
import bfs from "./bfs-patched";

const log = debug("pdfcpu:merge");

let stderrBuf = "";
const stderr = (str) => {
  stderrBuf += str;
  if (stderrBuf.includes("\n")) {
    log(stderrBuf.trim());
    stderrBuf = "";
  }
};

if (!WebAssembly.instantiateStreaming) {
  // polyfill
  WebAssembly.instantiateStreaming = async (resp, importObject) => {
    const source = await (await resp).arrayBuffer();
    return await WebAssembly.instantiate(source, importObject);
  };
}

let bfsProm;
const startBFS = async () => {
  if (!bfsProm) {
    bfsProm = bfs();
  }
  return bfsProm;
};

export default async function (files) {
  window.fs = await startBFS();
  const now = Date.now();
  let outputBuf = "";
  const filenames = await Promise.all(
    files.map(async (file, i) => {
      const buf = await file.arrayBuffer();
      console.log(buf);
      const fName = `/${now}-${i}.pdf`;
      const ret = window.fs.writeFileSync(fName, Buffer.from(buf));
      console.log(ret);
      return fName;
    })
  );
  log(filenames);
  const go = new Go();
  const result = await WebAssembly.instantiateStreaming(
    fetch(pdfcpu),
    go.importObject
  );
  go.argv = [
    "pdfcpu.wasm",
    "merge",
    "-conf",
    "disable",
    "/out.pdf",
    ...filenames,
  ];
  // go.argv = ["pdfcpu.wasm", "help"];
  // try {
  try {
    await go.run(result.instance);
    const arrayBuffer = fs.readFileSync(`/out.pdf`);
    log(arrayBuffer);
    const url = URL.createObjectURL(new Blob([arrayBuffer]));
    const a = document.createElement("a");
    a.download = "out.pdf";
    a.href = url;
    a.innerHTML = "Download";
    // document.body.appendChild(a);
    a.click();
  } catch (e) {
    log("bad exit");
    console.error(e);
  }
}
