import debug from "debug";
import * as BrowserFS from "browserfs";
const Buffer = BrowserFS.BFSRequire("buffer").Buffer;
global.Buffer = BrowserFS.BFSRequire("buffer").Buffer;
window.Buffer = global.Buffer;

// BrowserFS patched to work with pdfcpu per article here:
// https://github.com/wcchoi/go-wasm-pdfcpu/blob/master/article.md

const log = debug("pdfcpu:bfs");

let bfsPromise;
async function startBrowserFS() {
  if (!bfsPromise) {
    bfsPromise = new Promise((resolve, reject) => {
      BrowserFS.configure({ fs: "InMemory" }, (e) => {
        if (e) {
          return reject(err);
        }
        resolve();
      });
    });
  }
  return bfsPromise;
}

export default async function bfsPatched() {
  await startBrowserFS();
  const fs = BrowserFS.BFSRequire("fs");

  let outputBuf = "";
  fs.writeSyncOriginal = fs.writeSync;
  fs.writeSync = function (fd, buf) {
    if (fd === 1 || fd === 2) {
      outputBuf += new TextDecoder().decode(buf);
      const nl = outputBuf.lastIndexOf("\n");
      if (nl != -1) {
        log(outputBuf.substr(0, nl));
        outputBuf = outputBuf.substr(nl + 1);
      }
      return buf.length;
    } else {
      return fs.writeSyncOriginal(...arguments);
    }
  };

  fs.writeOriginal = fs.write;
  fs.write = function (...args) {
    const [fd, buf, offset, length, position, callback] = args;
    if (fd === 1 || fd === 2) {
      if (offset !== 0 || length !== buf.length || position !== null) {
        throw new Error("not implemented");
      }
      const n = this.writeSync(fd, buf);
      callback(null, n, buf);
    } else {
      // buf:
      args[1] = Buffer.from(args[1]);
      return fs.writeOriginal(...args);
    }
  };
  fs.closeOriginal = fs.close;
  fs.close = function (fd, callback) {
    return fs.closeOriginal(fd, function (...args) {
      if (typeof args[0] === "undefined") args[0] = null;
      return callback(...args);
    });
  };
  fs.constants = {
    O_RDONLY: 0,
    O_WRONLY: 1,
    O_RDWR: 2,
    O_CREAT: 64,
    O_EXCL: 128,
    O_NOCTTY: 256,
    O_TRUNC: 512,
    O_APPEND: 1024,
    O_DIRECTORY: 65536,
    O_NOATIME: 262144,
    O_NOFOLLOW: 131072,
    O_SYNC: 1052672,
    O_DIRECT: 16384,
    O_NONBLOCK: 2048,
  };
  const openOriginal = fs.open.bind(fs);
  fs.open = function (path, flags, mode, callback) {
    var myflags = "r";
    var O = fs.constants;

    // Convert numeric flags to string flags
    // FIXME: maybe wrong...
    if (flags & O.O_WRONLY) {
      // 'w'
      myflags = "w";
      if (flags & O.O_EXCL) {
        myflags = "wx";
      }
    } else if (flags & O.O_RDWR) {
      // 'r+' or 'w+'
      if (flags & O.O_CREAT && flags & O.O_TRUNC) {
        // w+
        if (flags & O.O_EXCL) {
          myflags = "wx+";
        } else {
          myflags = "w+";
        }
      } else {
        // r+
        myflags = "r+";
      }
    } else if (flags & O.O_APPEND) {
      // 'a'
      throw "Not implmented";
    }
    // TODO: handle other cases

    return openOriginal(path, myflags, mode, callback);
  };
  fs.fstatOriginal = fs.fstat;
  fs.fstat = function (fd, callback) {
    return fs.fstatOriginal(fd, function (...args) {
      var retStat = args[1];
      delete retStat["fileData"];
      retStat.atimeMs = retStat.atime.getTime();
      retStat.mtimeMs = retStat.mtime.getTime();
      retStat.ctimeMs = retStat.ctime.getTime();
      retStat.birthtimeMs = retStat.birthtime.getTime();
      return callback(args[0], retStat);
    });
  };
  var handler = {
    // get: function (target, property) {
    //   if (property in target && target[property] instanceof Function) {
    //     return function (...args) {
    //       log(property, "called", args);
    //       if (args[args.length - 1] instanceof Function) {
    //         var origCB = args[args.length - 1];
    //         function newCB() {
    //           log("callback for", property, "get called with args:", args);
    //           return Reflect.apply(origCB, this, args);
    //         }
    //         args[args.length - 1] = newCB;
    //       }
    //       return Reflect.apply(target[property], target, args);
    //     };
    //   } else {
    //     return target[property];
    //   }
    // },
  };
  return new Proxy(fs, handler);
}
