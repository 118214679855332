import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import merge from "./pdfcpu-wasm-merge";

import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    height: 100%;
  }
`;

const ContainerBox = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const Centered = styled.div`
  margin: auto;
`;

const Button = styled.button`
  margin: 5px;
`;

const Picker = styled.input`
  margin: 5px;
`;

const App = () => {
  const [files, setFiles] = useState([]);
  const [running, setRunning] = useState(false);
  return (
    <ContainerBox>
      <GlobalStyle />
      <Centered>
        <Picker
          type="file"
          multiple
          onChange={(e) => setFiles([...e.target.files])}
        />
        <ul>
          {files.map((file, i) => (
            <li key={i}>{file.name}</li>
          ))}
        </ul>
        <Button
          disabled={files.length === 0 || running}
          onClick={async () => {
            setRunning(true);
            await merge(files);
            setRunning(false);
          }}
        >
          {running ? "Merging..." : "Merge"}
        </Button>
        <Button onClick={() => setFiles([])}>Clear</Button>
      </Centered>
    </ContainerBox>
  );
};

const container = document.querySelector("main");
const root = createRoot(container);
root.render(<App />);
